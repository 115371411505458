<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-section">
        <b-form v-on:submit.prevent="onSubmit" v-if="show">
          <b-form-group
            id="input-group-1"
            label="Text:"
            label-for="input-1"
            description="Paste full text of daily horoscope..."
          >
            <b-form-textarea
              id="textarea"
              v-model="text"
              rows="30"
              required
              max-rows="100"
            ></b-form-textarea>
          </b-form-group>
          <div class="form-submit">
            <b-button type="submit" variant="secondary">Submit</b-button>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.form-section {
  background-color: white;
  padding: 10px;
  margin-top: 10px;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      text: "",
      show: true
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      return new Promise(resolve => {
        ApiService.post("dailyHoroscope", this.text)
          .then(({ data }) => {
            if (data && data.response && data.response.status) {
              this.$bvToast.toast("Horoscope has been saved sucessfully!", {
                title: "Success",
                variant: "success"
              });
            } else {
              this.$bvToast.toast(
                "Something went wrong while inserting horoscope...",
                {
                  title: "Error",
                  variant: "danger"
                }
              );
            }
            resolve();
          })
          .catch(({ response }) => {
            let message = "Something went wrong while inserting horoscope...";
            if (
              response &&
              response.data &&
              response.data.error &&
              response.data.error.message
            ) {
              message = response.data.error.message;
            }
            this.$bvToast.toast(message, {
              title: "Error",
              variant: "danger"
            });
          });
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Daily Horoscope", route: "dailyHoroscope" },
      { title: "Horoscope Builder" }
    ]);
  }
};
</script>
